<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import simplebar from "simplebar-vue";

import AdaptiveSubscribe from "@/components/lazy/subscribe/AdaptiveSubscribe";

export default {
  page: {
    title: "Subscribe",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    AdaptiveSubscribe,
    /* eslint-disable vue/no-unused-components */
    simplebar,
    Layout
  },
  data() {
    return {
      ready: false,
      plans: []
    };
  },
  created() {

  },
  validations: {

  },
  methods: {

  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Subscribe
        <router-link to="/account" class="small ml-2">
          <i class="fas fa-backspace"></i> Go back
        </router-link>
      </h4>
      <div class="page-title-right bv-d-xs-down-none"><ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <router-link to="/">
            CFTools Cloud
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span aria-current="location">
            Subscribe
          </span>
        </li>
      </ol>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h4>{{ $t('subscribe.title') }}</h4>
          <p class="text-muted">{{ $t('subscribe.description') }}</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="alert alert-danger">
          <h3 class="text-black p-0">
            The subscribe process is currently unavailable. Subscriptions will not expire until this issue is resolved.
          </h3>
        </div>
      </div>
    </div>
    <AdaptiveSubscribe></AdaptiveSubscribe>
  </Layout>
</template>
