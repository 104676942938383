<script>
export default {
  components: {

  },
  props: {

  },
  methods: {
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/subscription/status', {credentials: 'include'})
          .then(response => {
            if(response.ok){
              return response.json();
            }
          })
          .then(data => {
            //this.subscription = data.subscription;
            this.ready = true;
          });
    }
  },
  created() {
    this.getData()
  },
  data() {
    return {
      ready: false,
      subscription: {}
    }
  }
};
</script>

<template>
  <div class="row" v-if="subscription">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4">
              <div class="media">
                <div class="media-body align-self-center">
                  <div class="text-muted">
                    <p class="mb-2">{{$t('dashboard.welcome_back')}}</p>
                    <h5 class="mb-1">philipp</h5>
                    <p class="mb-0">CFTools staff</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 align-self-center">
              <div class="text-lg-center mt-4 mt-lg-0">
                <div class="row">
                  <div class="col-4">
                    <div>
                      <p class="text-muted text-truncate mb-2">{{$t('terms.servers')}}</p>
                      <h5 class="mb-0">3</h5>
                    </div>
                  </div>
                  <div class="col-4">
                    <div>
                      <p class="text-muted text-truncate mb-2">{{$t('dashboard.notification_credit')}}</p>
                      <h5 class="mb-0">0 remaining</h5>
                    </div>
                  </div>
                  <div class="col-4">
                    <div>
                      <p class="text-muted text-truncate mb-2">{{$t('dashboard.storage')}}</p>
                      <h5 class="mb-0">1 MiB / 100 MiB</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 d-none d-lg-block">
              <div class="clearfix mt-4 mt-lg-0">
                <b-dropdown class="float-right" right variant="primary">
                  <template v-slot:button-content>
                    Request limit increase
                  </template>
                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another action</b-dropdown-item>
                  <b-dropdown-item>Something else</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
