<script>
import Storefront from '@/components/lazy/subscribe/Storefront';
import SubscriptionStatus from "@/components/lazy/subscribe/SubscriptionStatus";

export default {
  components: {
    Storefront,
    //SubscriptionStatus
  },
  props: {

  },
  methods: {

  },
  created() {

  },
  data() {
    return {

    }
  }
};
</script>

<template>
  <div>
    <!-- <SubscriptionStatus></SubscriptionStatus> -->
    <Storefront></Storefront>
  </div>
</template>
