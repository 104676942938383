<style>
.method-blocked {
  pointer-events: none;
}
</style>

<script>
/*
  NOTE: Ported from preliminary source
  TODO: Rework this
  Storefront and sub pages have been created in a preliminary project and only ported tue Vue (& Cloud UI) last minute.
  Functionality, conventions etc. are not up to date.
 */
import {mapGetters} from "vuex";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import { with_acrf_token } from '@/methods';
import { FormWizard, TabContent } from "vue-form-wizard";

import Loading from '@/components/lazy/Loading';
import OmegaFree from '@/components/lazy/subscribe/features/OmegaFree';
import OmegaBasic from '@/components/lazy/subscribe/features/OmegaBasic';
import OmegaPro from '@/components/lazy/subscribe/features/OmegaPro';
import OmegaCommunity from '@/components/lazy/subscribe/features/OmegaCommunity';
import store from "@/state/store";
import {
  BillingProvider,
  PayPalBillingAgreementStatus,
  ProductBillingCycle,
  ProductCategory,
  SubscriptionStatus
} from "@/enums";

export default {
  components: {
    Loading,
    FormWizard,
    TabContent,
    OmegaFree,
    OmegaBasic,
    OmegaPro,
    OmegaCommunity
  },
  props: {

  },
  computed: {
    ...mapGetters(['getDTLocale']),
  },
  sockets: {
    subscription_error(data) {
      // Error table at Plutus / PayPal
      this.overlay_text = 'PayPal error (0x0)';
      this.toggleOverlay();
      setTimeout(() => {location.reload();}, 3000);
      this.$swal({
        icon: 'error',
        text: 'PayPal error'
      }).then(() => {
        location.reload();
      });
    },
    event_subscription(data) {
      this.getData();
      if(data.status === SubscriptionStatus.ACTIVE) {
        this.$swal({
          icon: 'success',
          text: this.$t('subscribe.process.payment.success')
        });
        let updated = false;
        this.subscriptions.forEach((subscription) => {
          if(subscription.subscription_id === data.subscription_id) {
            this.subscription[subscription.category].status = data.status;
            updated = true;
          }
        });
        if(!updated) {
          setTimeout(() => {
            this.getData();
          }, 1000);
        }

      } else if(data.status === SubscriptionStatus.CONFIRMATION_PENDING) {
        this.overlay_text = this.$t('subscribe.process.payment.redirect');
        this.ensureOverlay();
        location.replace(data.forward_url);

      } else if(data.status === SubscriptionStatus.CANCELLED) {
        this.subscriptions.forEach((subscription) => {
          if(subscription.subscription_id === data.subscription_id && subscription.category !== ProductCategory.FREE) {
            this.subscription[subscription.category].status = data.status;
          }
        });

      } else {
        this.$swal({
          icon: 'info',
          text: this.$t('subscribe.process.errors.internal')
        });
      }

    },
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
      this.ready = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/subscribe/storefront', {credentials: 'include'})
        .then(response => {
          if(response.ok) {
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.storefront = data.storefront.sort((a, b) => (parseFloat(a.price) > parseFloat(b.price)) ? 1 : -1);
          this.location_locked = data.location_locked;
          this.country_prefilled = data.country;
          this.region_prefilled = data.region;
          this.subscriptions = data.subscriptions;
          this.subscriptions.forEach((subscription) => {
            if(subscription.category === ProductCategory.FREE) {
              this.is_free = true;
            } else {
              this.active_subscription = true;
            }
            this.subscription[subscription.category] = subscription;
          });

          this.storefront.forEach((product) => {
            this.products[product.product_id] = product;
            this.subscriptions.forEach((subscription) => {
              if(this.subscription[product.category]) {
                product.blocked = true;
              } else {
                product.blocked = false;
              }
            });
          });
          this.limits = data.limits;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    unavailable_location: function () {
      let blockedCountries = ['VE', 'KP'];
      return blockedCountries.includes(this.country);
    },
    validate_location: function() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if(this.location_locked) {
            this.country = this.country_prefilled;
            this.region = this.region_prefilled;
          }
          if(this.unavailable_location()) {
            reject('Unavailable for legal reasons');
          }
          if(this.region.length && this.country.length) {
            this.location_error = false;
            this.retrieveVAT(resolve, reject);
          }
          else {
            this.location_error = true;
            reject('Location must be selected');
          }
        }, 500);
      })
    },
    validate_method: function() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          reject('disabled');
          if(!this.provider || !this.type) reject('No method selected');
          if(this.type === 'manual' && (!this.period || ![3, 12].includes(this.period))) reject('Select your billing cycle');
          resolve(true);
        }, 500);
      })
    },
    validate_legal: function() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if(this.legal === true && this.waiver === true) {
            this.legal_error = false;
            resolve(true);
          }
          else {
            this.legal_error = true;
            reject('Terms must be accepted');
          }

        }, 500);
      })
    },
    form_completed: function() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.toggleOverlay();
          this.$bvModal.hide('subscribeModal');
          this.processOrder(this.product);
          resolve(true);
        }, 1);
      })
    },
    selectMethod(provider, type) {
      if(type === 'automatic' && !this.automaticAvailable()) return;
      this.provider = provider;
      this.type = type;
    },
    automaticAvailable() {
      let errorCountries = ['RU'];
      return !(errorCountries.includes(this.country));
    },
    order: function(product_id) {
      this.product = product_id;
      this.country = null;
      this.region = null;
      this.legal = false;
      this.waiver = false;
      this.provider = 'paypal';
      this.type = 'automatic';
      this.period = 0;
    },
    cancel: function(subscription_id) {
      let that = this;
      this.$swal({
        icon: 'warning',
        text: this.$t('subscribe.cancel.message'),
        title: this.$t('subscribe.cancel.title'),
        confirmButtonText: this.$t('subscribe.cancel.button'),
        showCancelButton: true,
      }).then(response => {
        if(response.isConfirmed) {
          that.overlay_text = this.$t('subscribe.cancel.overlay_text');
          that.toggleOverlay();

          with_acrf_token().then((r) => {
            let payload = {
              acsrf_token: r.acsrf_token,
              subscription_id: subscription_id
            };
            fetch(process.env.VUE_APP_ROOT_API + 'v1/subscription/cancel', {
              method: 'POST',
              body: JSON.stringify(payload),
              credentials: 'include'
            })
                .then(response => {
                  if(response.ok){
                    return response.json();
                  } else {
                    throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                  }
                })
                .then(data => {
                  that.getData();
                  that.toggleOverlay();
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('subscribe.cancel.processed'),
                    title: this.$t('subscribe.cancel.title')
                  })
                })
                .catch(error => {
                  console.log(`[ERROR] ${error}`);
                  that.toggleOverlay();
                  this.$swal({
                    icon: 'error',
                    text: this.$t('subscribe.cancel.error')
                  });
                });
          }).catch(error => {
            that.toggleOverlay();
            console.log('[ERROR] Failed to request acrf token');
            this.$swal({
              icon: 'error',
              text: this.$t('error.server.generic.message')
            });
          });
        }
      });
    },
    check_status: function(subscription_id) {
      this.overlay_text = this.$t('subscribe.process.payment.recheck');
      this.toggleOverlay();

      fetch(process.env.VUE_APP_ROOT_API + `v1/subscription/status?subscription_id=${subscription_id}` , {credentials: 'include'})
        .then(response => {
          if(response.ok) {
            return response.json();
          } else {
            if(response.status == 429) {
              this.$swal({
                icon: 'warning',
                text: this.$t('error.server.ratelimit.message'),
                title: this.$t('error.server.ratelimit.title')
              });
            } else
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          if(data.ba_status === PayPalBillingAgreementStatus.APPROVAL_PENDING) {
            let that = this;
            this.toggleOverlay();
            this.$swal({
              icon: 'info',
              text: this.$t('subscribe.process.payment.prompt')
            }).then(function() {
              that.ensureOverlay();
              that.overlay_text = that.$t('subscribe.process.payment.redirect');
              location.replace(data.confirm_url);
            });
          } else {
            this.toggleOverlay();
            this.$swal({
              icon: 'info',
              text: this.$t('subscribe.process.payment.waiting')
            })
          }
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            text: this.$t('error.server.generic.message'),
            title: this.$t('error.server.generic.title')
          });
        });
    },
    toggleOverlay: function() {
      document.body.classList.toggle("fullpage-overlay-enabled");
    },
    ensureOverlay: function() {
      document.body.classList.add("fullpage-overlay-enabled");
    },
    retrieveVAT: function(rs, rj) {
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          country: this.country,
          region: this.region
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/subscribe/vat', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.vat = data.vat.rate;
          this.vat_label = data.vat.label;
          rs(true);
        })
        .catch(error => {
          console.log(`[ERROR] ${error}`);
          this.$swal({
            icon: 'error',
            text: this.$t('subscribe.process.error_vat')
          });
          rj('Error');
        });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    },
    processOrder: function() {
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          product_id: this.product,
          country: this.country,
          region: this.region,
          provider: this.provider,
          method: this.type,
          period: this.period
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/subscribe/start', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
          .then(response => {
            if(response.ok){
              return response.json();
            } else {
              this.toggleOverlay();
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            this.getData();
            this.overlay_text = this.$t('subscribe.process.wait_confirm');
          })
          .catch(error => {
            console.log(`[ERROR] ${error}`);
            this.$swal({
              icon: 'error',
              text: this.$t('subscribe.process.error')
            });
          });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    }
  },
  created() {
    this.getData();
  },
  data() {
    return {
      countries: countries,
      ready: false,
      error: false,
      active_subscription: false,
      is_free: false,
      storefront: {},
      subscription: {},
      subscriptions: [],
      products: {},
      product: null,
      country: null,
      region: null,
      provider: 'paypal',
      type: 'automatic',
      period: 0,
      location_locked: false,
      region_prefilled: '',
      country_prefilled: 'DE',
      legal: false,
      waiver: false,
      legal_error: false,
      location_error: false,
      monthly: true,
      overlay_text: 'We are processing your request. Please stand by...',
      limits: {},
      vat: null,
      vat_label: null,
      ProductCategory: ProductCategory,
      BillingProvider: BillingProvider,
      SubscriptionStatus: SubscriptionStatus,
      ProductBillingCycle: ProductBillingCycle
    }
  }
};
</script>

<template>
  <div>
  <div class="fullpage-overlay">
    <div class="fullpage-overlay-content">
      <h3 class="text-warning">
        <i class="fad fa-spinner-third fa-spin mr-2"></i> {{ overlay_text }}
      </h3>
    </div>
  </div>
  <div v-if="ready">
    <div v-if="!storefront.length">
      <div class="row justify-content-center mt-lg-5">
        <div class="col-xl-5 col-sm-8">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i> {{ $t('subscribe.error.storefront.empty.title') }}
              </h5>
            </div>
            <div class="card-body">
              <h5 class="card-title mt-0">
                {{ $t('subscribe.error.storefront.empty.message') }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row justify-content-center">
        <div v-for="plan in storefront" :key="plan.price" class="col-xl-3 col-md-6">
          <div class="card plan-box" :class="(plan.blocked) ? (subscription[plan.category].status === SubscriptionStatus.ACTIVE) ? 'border border-primary' : 'border border-warning' : ''">
            <div class="card-body p-4" v-if="!(plan.blocked)">
              <div class="media">
                <div class="media-body">
                  <h5>{{$t(`omega.${plan.code}.title`)}}</h5>
                  <p class="text-muted">{{$t(`omega.${plan.code}.description`)}}</p>
                </div>
                <div class="ml-3" v-if="plan.recommended">
                  <span class="h2 badge badge-danger badge-pill" style="font-size: 18px;">🔥 Hot</span>
                </div>
              </div>
              <div class="py-4">
                <h2 v-if="plan.price > 0.0">
                  <sup>
                    <small>€</small>
                  </sup>
                  {{plan.price}} /
                  <span class="font-size-13">Per month</span>
                </h2>
                <h2 v-else>
                  {{$t('terms.free')}}
                  <br>
                </h2>
              </div>
              <div class="text-center plan-btn" v-if="(active_subscription && plan.category != ProductCategory.FREE)">
                <button
                    type="button"
                    @click="order(plan.product_id)"
                    class="btn btn-primary btn-md position-relative"
                    v-b-modal.subscribeModal
                    data-toggle="modal"
                    data-target="#subscribeModal"
                >
                  <div v-if="plan.price > 0.0">
                    {{ $t('subscribe.active.upgrade') }}
                  </div>
                  <div v-else>
                    {{ $t('terms.activate') }}
                  </div>
                </button>
              </div>
              <div class="text-center" v-else-if="(active_subscription && plan.category == ProductCategory.FREE)">
                <h5>{{ $t('subscribe.active.free_fallback') }}</h5>
              </div>
              <div class="text-center plan-btn" v-else>
                <button
                    type="button"
                    @click="order(plan.product_id)"
                    class="btn btn-primary btn-md position-relative"
                    v-b-modal.subscribeModal
                    data-toggle="modal"
                    data-target="#subscribeModal"
                >
                  <div v-if="plan.price > 0.0">
                    {{ $t('subscribe.prompt') }}
                  </div>
                  <div v-else>
                    {{ $t('terms.activate') }}
                  </div>
                </button>
              </div>
              <div class="mt-3"></div>
              <div class="plan-features" v-if="plan.code === 'free'">
                <OmegaFree></OmegaFree>
              </div>
              <div class="plan-features" v-else-if="plan.code === 'basic'">
                <OmegaBasic></OmegaBasic>
              </div>
              <div class="plan-features" v-else-if="plan.code === 'pro'">
                <OmegaPro></OmegaPro>
              </div>
              <div class="plan-features" v-else-if="plan.code === 'community'">
                <OmegaCommunity></OmegaCommunity>
              </div>
              <hr>
              <div class="row text-center" v-if="limits[plan.code]">
                <div class="col-sm-4">
                  <div class="mt-24 mt-sm-0">
                    <p class="text-muted mb-2">{{$t('terms.servers')}}</p>
                    <h5 v-if="limits[plan.code].servers == 0">
                      <h4 class="text-danger">0</h4>
                    </h5>
                    <h5 v-else-if="limits[plan.code].servers == -1">
                      <i class="fal fa-infinity"></i> {{ $t('terms.fairuse') }}
                    </h5>
                    <h5 v-else>
                      {{ limits[plan.code].servers }}
                    </h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2 mt-sm-0">
                    <p class="text-muted mb-2">{{$t('terms.banlists')}}</p>
                    <h5 v-if="limits[plan.code].banlists == 0">
                      <h4 class="text-danger">0</h4>
                    </h5>
                    <h5 v-else-if="limits[plan.code].banlists == -1">
                      <i class="fal fa-infinity"></i> {{ $t('terms.fairuse') }}
                    </h5>
                    <h5 v-else>
                      {{ limits[plan.code].banlists }}
                    </h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2 mt-sm-0">
                    <p class="text-muted mb-2">{{$t('terms.teams')}}</p>
                    <h5 v-if="limits[plan.code].teams == 0">
                      <h4 class="text-danger">0</h4>
                    </h5>
                    <h5 v-else-if="limits[plan.code].teams == -1">
                      <i class="fal fa-infinity"></i> {{ $t('terms.fairuse') }}
                    </h5>
                    <h5 v-else>
                      {{ limits[plan.code].teams }}
                    </h5>
                  </div>
                </div>
              </div>
              <div v-else class="row justify-content-center">
                <div class="col-sm">
                  <b-alert show variant="danger">
                    <i class="far fa-exclamation-circle mr-2"></i>
                    {{ $t('subscribe.error.limits.message') }}
                  </b-alert>
                </div>
              </div>
            </div>
            <!-- Plan is currently active -->
            <div class="card-body p-4" v-else-if="(plan.blocked && subscription[plan.category].status !== SubscriptionStatus.CONFIRMATION_PENDING )">
              <div class="media">
                <div class="media-body">
                  <h5>{{$t(`omega.${subscription[plan.category].code}.title`)}}
                    <small class="text-primary" v-if="subscription[plan.category].status === SubscriptionStatus.ACTIVE">{{$t('subscribe.active.title')}}</small>
                    <small class="text-warning" v-else>{{$t('subscribe.active.cancelled')}}</small>
                  </h5>
                  <p class="text-muted">{{$t(`omega.${subscription[plan.category].code}.description`)}}</p>
                </div>
              </div>
              <div class="py-4">
                <h2 v-if="subscription[plan.category].price > 0.0">
                  <sup>
                    <small>€</small>
                  </sup>
                  {{subscription[plan.category].price}} /
                  <template v-if="subscription[plan.category].billing_cycle === ProductBillingCycle.MONTHLY">
                    <span class="font-size-13">Per month</span>
                  </template>
                  <template v-if="subscription[plan.category].billing_cycle === ProductBillingCycle.QUARTERLY">
                    <span class="font-size-13">3 months</span>
                  </template>
                  <template v-if="subscription[plan.category].billing_cycle === ProductBillingCycle.YEARLY">
                    <span class="font-size-13">Per year</span>
                  </template>
                </h2>
                <h2 v-else>
                  {{$t('terms.free')}}
                  <br>
                </h2>
                <h4>
                  <template v-if="subscription[plan.category].status === SubscriptionStatus.ACTIVE">
                    <template v-if="subscription[plan.category].billing_provider === BillingProvider.PAYPAL_MANUAL">
                      {{$t('subscribe.active.expires')}}
                    </template>
                    <template v-else>
                      {{$t('subscribe.active.renews')}}
                    </template>
                  </template>
                  <template v-else-if="subscription[plan.category].status === SubscriptionStatus.CANCELLED">
                    {{$t('subscribe.active.expires')}}
                  </template>
                  {{ $d(Date.parse(subscription[plan.category].expires_at, {year: 'numeric', month: 'numeric', day: 'numeric'}), 'date', getDTLocale()) }}
                </h4>
              </div>
              <div class="text-center" v-if="(subscription[plan.category].category !== ProductCategory.FREE && subscription[plan.category].status !== SubscriptionStatus.CANCELLED)">
                <button
                    type="button"
                    @click="cancel(subscription[plan.category].subscription_id)"
                    class="btn btn-danger btn-sm position-relative"
                >
                  <div>
                    {{ $t('subscribe.active.cancel') }}
                  </div>
                </button>
              </div>
              <div class="text-center" v-else-if="subscription[plan.category].status === SubscriptionStatus.CANCELLED">
                <button
                    type="button"
                    @click="order(plan.product_id)"
                    class="btn btn-success btn-md position-relative"
                    v-b-modal.subscribeModal
                    data-toggle="modal"
                    data-target="#subscribeModal"
                >
                  <div v-if="plan.price > 0.0">
                    {{ $t('subscribe.active.renew') }}
                  </div>
                  <div v-else>
                    {{ $t('terms.activate') }}
                  </div>
                </button>
              </div>
              <hr>
              <div class="row text-center" v-if="limits[plan.code]">
                <div class="col-sm-4">
                  <div class="mt-24 mt-sm-0">
                    <p class="text-muted mb-2">{{$t('terms.servers')}}</p>
                    <h5 v-if="limits[plan.code].servers == 0">
                      <h4 class="text-danger">0</h4>
                    </h5>
                    <h5 v-else-if="limits[plan.code].servers == -1">
                      <i class="fal fa-infinity"></i> {{ $t('terms.fairuse') }}
                    </h5>
                    <h5 v-else>
                      {{ limits[plan.code].servers }}
                    </h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2 mt-sm-0">
                    <p class="text-muted mb-2">{{$t('terms.banlists')}}</p>
                    <h5 v-if="limits[plan.code].banlists == 0">
                      <h4 class="text-danger">0</h4>
                    </h5>
                    <h5 v-else-if="limits[plan.code].banlists == -1">
                      <i class="fal fa-infinity"></i> {{ $t('terms.fairuse') }}
                    </h5>
                    <h5 v-else>
                      {{ limits[plan.code].banlists }}
                    </h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2 mt-sm-0">
                    <p class="text-muted mb-2">{{$t('terms.teams')}}</p>
                    <h5 v-if="limits[plan.code].teams == 0">
                      <h4 class="text-danger">0</h4>
                    </h5>
                    <h5 v-else-if="limits[plan.code].teams == -1">
                      <i class="fal fa-infinity"></i> {{ $t('terms.fairuse') }}
                    </h5>
                    <h5 v-else>
                      {{ limits[plan.code].teams }}
                    </h5>
                  </div>
                </div>
              </div>
              <div v-else class="row justify-content-center">
                <div class="col-sm">
                  <b-alert show variant="danger">
                    <i class="far fa-exclamation-circle mr-2"></i>
                    {{ $t('subscribe.error.limits.message') }}
                  </b-alert>
                </div>
              </div>
            </div>
            <div class="card-body p-4" v-else-if="(plan.blocked && subscription[plan.category].status === SubscriptionStatus.CONFIRMATION_PENDING )">
              <div class="media">
                <div class="media-body">
                  <h5>{{$t(`omega.${subscription[plan.category].code}.title`)}}
                    <small class="text-warning">{{$t('subscribe.active.pending')}}</small>
                  </h5>
                  <p class="text-muted">{{$t(`omega.${subscription[plan.category].code}.description`)}}</p>
                </div>
              </div>
              <div class="py-4">
                <h2 v-if="subscription[plan.category].price > 0.0">
                  <sup>
                    <small>€</small>
                  </sup>
                  {{subscription[plan.category].price}} /
                  <template v-if="subscription[plan.category].billing_cycle === ProductBillingCycle.MONTHLY">
                    <span class="font-size-13">Per month</span>
                  </template>
                  <template v-if="subscription[plan.category].billing_cycle === ProductBillingCycle.QUARTERLY">
                    <span class="font-size-13">3 months</span>
                  </template>
                  <template v-if="subscription[plan.category].billing_cycle === ProductBillingCycle.YEARLY">
                    <span class="font-size-13">Per year</span>
                  </template>
                </h2>
                <h2 v-else>
                  {{$t('terms.free')}}
                  <br>
                </h2>
                <h4>
                  {{$t('subscribe.active.pending')}}
                </h4>
              </div>
              <div class="text-center" v-if="subscription[plan.category].category !== ProductCategory.FREE">
                <button
                    type="button"
                    @click="check_status(subscription[plan.category].subscription_id)"
                    class="btn btn-warning btn-md position-relative"
                >
                  <div>
                    {{ $t('subscribe.active.checkstatus') }}
                  </div>
                </button>

              </div>
              <hr>
              <div class="row text-center" v-if="limits[plan.code]">
                <div class="col-sm-4">
                  <div class="mt-24 mt-sm-0">
                    <p class="text-muted mb-2">{{$t('terms.servers')}}</p>
                    <h5 v-if="limits[plan.code].servers == 0">
                      <h4 class="text-danger">0</h4>
                    </h5>
                    <h5 v-else-if="limits[plan.code].servers == -1">
                      <i class="fal fa-infinity"></i> {{ $t('terms.fairuse') }}
                    </h5>
                    <h5 v-else>
                      {{ limits[plan.code].servers }}
                    </h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2 mt-sm-0">
                    <p class="text-muted mb-2">{{$t('terms.banlists')}}</p>
                    <h5 v-if="limits[plan.code].banlists == 0">
                      <h4 class="text-danger">0</h4>
                    </h5>
                    <h5 v-else-if="limits[plan.code].banlists == -1">
                      <i class="fal fa-infinity"></i> {{ $t('terms.fairuse') }}
                    </h5>
                    <h5 v-else>
                      {{ limits[plan.code].banlists }}
                    </h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2 mt-sm-0">
                    <p class="text-muted mb-2">{{$t('terms.teams')}}</p>
                    <h5 v-if="limits[plan.code].teams == 0">
                      <h4 class="text-danger">0</h4>
                    </h5>
                    <h5 v-else-if="limits[plan.code].teams == -1">
                      <i class="fal fa-infinity"></i> {{ $t('terms.fairuse') }}
                    </h5>
                    <h5 v-else>
                      {{ limits[plan.code].teams }}
                    </h5>
                  </div>
                </div>
              </div>
              <div v-else class="row justify-content-center">
                <div class="col-sm">
                  <b-alert show variant="danger">
                    <i class="far fa-exclamation-circle mr-2"></i>
                    {{ $t('subscribe.error.limits.message') }}
                  </b-alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-7 col-sm-12">
          <div class="card card-body">
            <div class="row">
              <div class="col-lg-8 col-sm-12">
                <h3>{{ $t('solutions.storefront.title') }} <small>{{ $t('solutions.storefront.small') }}</small></h3>
                <div class="h6">
                  {{ $t('solutions.storefront.message') }}
                </div>
              </div>
              <div class="col-lg-4 col-sm-12 justify-content-center text-center">
                <a href="mailto:inquiry@cftools.software?subject=Consulting%20and%20support%20inquiry">
                  <button type="button" class="btn w-sm btn-primary">
                    <i class="fas fa-envelope-open-text font-size-16 d-block"></i> {{ $t('solutions.storefront.prompt') }}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <div class="col-lg-12">
      <Loading></Loading>
    </div>
  </div>  
  <b-modal id="subscribeModal" size="lg" centered v-if="product" :title="$t('subscribe.process.title')" hide-footer>
    <template v-if="products[product].code !== 'free'">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="alert alert-danger">
            <h4 class="p-0 text-black"> 
              The subscribe process is currently unavailable. Subscriptions will not expire until this issue is resolved.
            </h4>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <form-wizard
          shape="circle"
          errorColor="#f46a6a"
          color="#4285F4"
          @on-complete="form_completed"
          :start-index="0"
          :next-button-text="$t('generic.next')"
          :back-button-text="$t('generic.back')"
          :finish-button-text="products[product].price > 0.0 ? $t('subscribe.process.order') : $t('terms.activate')"
      >
        <tab-content icon="far fa-globe" :title="$t('subscribe.process.location')" class="kyc-wizard" :before-change="validate_location">
          <form v-if="!location_locked">
            <div class="row justify-content-center" v-if="unavailable_location() === true">
              <div class="col-lg-8">
                <b-alert show variant="danger">
                  <i class="far fa-exclamation-circle mr-2"></i>
                  {{ $t('subscribe.process.location_unavailable') }}
                </b-alert>
              </div>
            </div>

            <div class="row justify-content-center" v-if="location_error && (!country || !region)">
              <div class="col-lg-8">
                <b-alert show variant="danger">
                  <i class="far fa-exclamation-circle mr-2"></i>
                  {{ $t('subscribe.process.location_error') }}
                </b-alert>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="form-group">
                  <label>{{ $t('generic.country') }}</label>
                  <country-select
                      className="custom-select"
                      :placeholder="$t('subscribe.process.select_region')"
                      v-model="country"
                      :country="country"
                      :usei18n=false
                      :topCountry="country_prefilled"
                      :removePlaceholder=true
                  />
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="form-group">
                  <label>{{ $t('generic.region') }}</label>
                  <region-select
                      className="custom-select"
                      :placeholder="$t('subscribe.process.select_region')"
                      v-model="region"
                      :country="country"
                      :region="region"

                      :usei18n=false
                  />
                </div>
              </div>
            </div>
          </form>
          <form v-else>
            <div class="row justify-content-center" v-if="unavailable_location() === true">
              <div class="col-lg-8">
                <b-alert show variant="danger">
                  <i class="far fa-exclamation-circle mr-2"></i>
                  {{ $t('subscribe.process.location_unavailable') }}
                </b-alert>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-10">
                <b-alert show variant="info">
                  <i class="far fa-exclamation-circle mr-2"></i>
                  {{ $t('subscribe.process.location_locked') }}
                  <b>
                    ({{region_prefilled}}, {{ countries.getName(country_prefilled, 'en', {select: "official"}) }})
                  </b>
                </b-alert>
              </div>
            </div>
          </form>
        </tab-content>
        <tab-content icon="far fa-gavel" :title="$t('subscribe.process.legal')" class="kyc-wizard" :before-change="validate_legal">
          <form>
            <div class="row justify-content-center" v-if="legal_error && (!legal || !waiver)">
              <div class="col-lg-8">
                <b-alert show variant="danger">
                  <i class="far fa-exclamation-circle mr-2"></i>
                  {{ $t('subscribe.process.legal_error') }}
                </b-alert>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12" align="center">
                <b-button variant="light" class="btn-label mr-4" href="https://cftools.cloud/legal/terms-of-use" target="_blank">
                  <i class="far fa-external-link label-icon"></i> {{ $t('generic.tos') }}
                </b-button>

                <b-button variant="light" class="btn-label ml-4" href="https://cftools.cloud/legal/dpa" target="_blank">
                  <i class="far fa-external-link label-icon"></i> {{ $t('generic.dpa') }}
                </b-button>
              </div>
            </div>
            <div class="row justify-content-center mt-4">
              <div class="col-lg-8">
                <b-form-checkbox
                    v-model="legal"
                    class="custom-checkbox mb-3"
                >
                  {{ $t('subscribe.process.legal_accept') }}
                </b-form-checkbox>

              </div>
            </div>
            <div class="row justify-content-center mt-4">
              <div class="col-lg-8">
                <b-form-checkbox
                    v-model="waiver"
                    class="custom-checkbox mb-3"
                >
                  {{ $t('subscribe.process.waiver_accept') }}
                </b-form-checkbox>
              </div>
            </div>
          </form>
        </tab-content>
        <tab-content icon="far fa-money-check-edit-alt" :title="$t('subscribe.process.method.title')" class="kyc-wizard" :before-change="validate_method" v-if="products[product].price > 0.0">
          <form>
            <div class="row justify-content-center" v-if="!automaticAvailable()">
              <div class="col-lg-8">
                <b-alert show variant="danger">
                  <i class="far fa-exclamation-circle mr-2"></i>
                  {{ $t('subscribe.process.method.unavailable.disclaimer') }}
                </b-alert>
              </div>
            </div>
            <div class="row justify-content-center mt-4">
              <b-alert show variant="danger">
                <i class="far fa-exclamation-circle mr-2"></i>
                Subscribing has been disabled for technical reasons, check back later!
              </b-alert>
              <!--
              <div class="col-xl-4 col-sm-12"
                   :disabled="!automaticAvailable()"
                   :class="{'disabled': !automaticAvailable(), 'method-blocked': !automaticAvailable()}"
                   v-on:click="selectMethod('paypal', 'automatic')"
              >
                <label class="card-radio-label mb-3">
                  <input type="radio" name="pay-method" class="card-radio-input">
                  <div class="card-radio" :class="{'border-danger': !automaticAvailable()}">
                    <i class="fab fa-paypal font-size-24 text-primary mt-2 me-2"></i>
                    <h5 class="d-inline-block p-0 align-middle ml-2">
                      PayPal (Subscription)
                      <div class="small text-danger" v-if="!automaticAvailable()">
                        {{ $t('subscribe.process.method.unavailable.info') }}
                      </div>
                      <div class="small text-info" v-else>
                        {{ $t('subscribe.process.method.automatic.info') }}
                      </div>
                    </h5>
                  </div>
                </label>
              </div>

              <div class="col-xl-4 col-sm-12" v-on:click="selectMethod('paypal', 'manual')">
                <label class="card-radio-label mb-3">
                  <input type="radio" name="pay-method" class="card-radio-input">
                  <div class="card-radio">
                    <i class="fab fa-paypal font-size-24 text-primary mt-2 me-2"></i>
                    <h5 class="d-inline-block p-0 align-middle ml-2">
                      PayPal (Manual)
                      <div class="small text-warning">
                        {{ $t('subscribe.process.method.manual.info') }}
                      </div>
                    </h5>
                  </div>
                </label>
              </div>
              -->
              <!-- v-if="['DE'].includes(country)"> -->
            </div>
            <div class="row mt-1 text-center" v-if="this.type === 'manual'" >
              <div class="col-lg-12 col-sm-12 justify-content-center">
                <h4 class="text-center">
                  {{ $t('subscribe.process.method.period.prompt') }}
                </h4>
                <b-form-group>
                  <b-form-radio-group
                      v-model="period"
                      name="radio-sub-component"
                  >
                    <b-form-radio :value="3">{{ $t('subscribe.process.method.period.quarter') }}</b-form-radio>
                    <b-form-radio :value="12">{{ $t('subscribe.process.method.period.year') }}</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </form>
        </tab-content>
        <tab-content icon="far fa-check-circle" :title="$t('subscribe.process.confirm')" class="kyc-wizard" >
          <form>
            <div class="row justify-content-center">
              <div class="col-lg-8 text-center">
                <h5>{{$t('subscribe.process.confirm')}}</h5>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8 text-center">
                <h3>CFTools Cloud {{$t(products[product].name)}} {{$t('generic.subscriptionl')}}</h3>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8 text-center" v-if="products[product].price > 0.0">
                <template v-if="this.type === 'manual' && this.period">
                  <h4>{{ Number.parseFloat(products[product].price) * this.period }} € /
                    <small v-if="this.period === 3">
                      {{ $t('subscribe.process.method.period.quarter') }}
                    </small>
                    <small v-else-if="this.period === 12">
                      {{ $t('subscribe.process.method.period.year') }}
                    </small>
                  </h4>
                  <h5>{{$t('terms.includesl')}} {{ vat }} % {{ $t('subscribe.vat') }}</h5>
                  <h6>{{ $t('subscribe.process.waiver') }}</h6>
                </template>
                <template v-else>
                  <h4>{{ Number.parseFloat(products[product].price) }} € / <small>{{$t('subscribe.per_monthl')}}</small></h4>
                  <h5>{{$t('terms.includesl')}} {{ vat }} % {{ $t('subscribe.vat') }}</h5>
                  <h6>{{ $t('subscribe.process.waiver') }}</h6>
                </template>
              </div>
            </div>
          </form>
        </tab-content>
      </form-wizard>
     </template>
  </b-modal>

  <hr>
  <div class="row justify-content-center">
    <div class="col-8 checkout-tabs">
      <b-tabs pills vertical nav-class="p-0" nav-wrapper-class="col-lg-2 w-100">
        <b-tab active>
          <template v-slot:title>
            <i class="fad fa-question d-block check-nav-icon mt-4 mb-2" style="font-size: 36px; line-height: 1;"></i>
            <p class="font-weight-bold mb-4">{{ $t('subscribe.faq.general.title') }}</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <div class="tab-pane fade show active">
                  <div class="faq-box media mb-4">
                    <div class="faq-icon mr-3">
                      <i class="bx bx-help-circle font-size-20 text-success"></i>
                    </div>
                    <div class="media-body">
                      <h5 class="font-size-15">{{ $t('subscribe.faq.general.free.question') }}</h5>
                      <p class="text-muted">{{ $t('subscribe.faq.general.free.answer') }}</p>
                    </div>
                  </div>
                  <div class="faq-box media mb-4">
                    <div class="faq-icon mr-3">
                      <i class="bx bx-help-circle font-size-20 text-success"></i>
                    </div>
                    <div class="media-body">
                      <h5 class="font-size-15">{{ $t('subscribe.faq.general.fulfillment.question') }}</h5>
                      <p class="text-muted">{{ $t('subscribe.faq.general.fulfillment.answer') }}</p>
                    </div>
                  </div>
                  <div class="faq-box media mb-4">
                    <div class="faq-icon mr-3">
                      <i class="bx bx-help-circle font-size-20 text-success"></i>
                    </div>
                    <div class="media-body">
                      <h5 class="font-size-15">{{ $t('subscribe.faq.general.upgrade.question') }}</h5>
                      <p class="text-muted">{{ $t('subscribe.faq.general.upgrade.answer') }}</p>
                    </div>
                  </div>
                  <div class="faq-box media mb-4">
                    <div class="faq-icon mr-3">
                      <i class="bx bx-help-circle font-size-20 text-success"></i>
                    </div>
                    <div class="media-body">
                      <h5 class="font-size-15">{{ $t('subscribe.faq.general.duration.question') }}</h5>
                      <p class="text-muted">{{ $t('subscribe.faq.general.duration.answer') }}</p>
                    </div>
                  </div>
                  <div class="faq-box media mb-4">
                    <div class="faq-icon mr-3">
                      <i class="bx bx-help-circle font-size-20 text-success"></i>
                    </div>
                    <div class="media-body">
                      <h5 class="font-size-15">{{ $t('subscribe.faq.general.fairuse.question') }}</h5>
                      <p class="text-muted">{{ $t('subscribe.faq.general.fairuse.answer') }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <i class="fad fa-shopping-cart d-block check-nav-icon mt-4 mb-2" style="font-size: 36px; line-height: 1;"></i>
            <p class="font-weight-bold mb-4">{{ $t('subscribe.faq.payment.title') }}</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <div class="tab-pane">
                  <div class="faq-box media mb-4">
                    <div class="faq-icon mr-3">
                      <i class="bx bx-help-circle font-size-20 text-success"></i>
                    </div>
                    <div class="media-body">
                      <h5 class="font-size-15">{{ $t('subscribe.faq.payment.methods.question') }}</h5>
                      <p class="text-muted">{{ $t('subscribe.faq.payment.methods.answer') }}</p>
                    </div>
                  </div>
                  <div class="faq-box media mb-4">
                    <div class="faq-icon mr-3">
                      <i class="bx bx-help-circle font-size-20 text-success"></i>
                    </div>
                    <div class="media-body">
                      <h5 class="font-size-15">{{ $t('subscribe.faq.payment.cancellation.question') }}</h5>
                      <p class="text-muted">{{ $t('subscribe.faq.payment.cancellation.answer') }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <i class="fad fa-table d-block check-nav-icon mt-4 mb-2" style="font-size: 36px; line-height: 1;"></i>
            <p class="font-weight-bold mb-4">{{ $t('subscribe.faq.features.title') }}</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead class="table-dark">
                      <tr>
                        <th>{{ $t('subscribe.faq.features.feature') }}</th>
                        <th>Free</th>
                        <th>Basic</th>
                        <th>Pro</th>
                        <th>Community</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.rcon') }}</th>
                        <td>24/7</td>
                        <td>24/7</td>
                        <td>24/7</td>
                        <td>24/7</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.support') }}</th>
                        <td>❌</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.logs_audit') }}</th>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.temporary', {period: '7d'}) }}</td>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.permanent') }}</td>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.permanent') }}</td>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.permanent') }}</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.logs_rcon') }}</th>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.temporary', {period: '1d'}) }}</td>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.temporary', {period: '7d'}) }}</td>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.temporary', {period: '30d'}) }}</td>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.temporary', {period: '30d'}) }}</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.logs_server') }}</th>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.temporary', {period: '2d'}) }}</td>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.temporary', {period: '30d'}) }}</td>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.temporary', {period: '30d'}) }}</td>
                        <td>✅<br>{{ $t('subscribe.faq.features.retention.temporary', {period: '90d'}) }}</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.security') }}</th>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.whitelist') }}</th>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.commands') }}</th>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.filter_name') }}</th>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.filter_chat') }}</th>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.scheduler') }}</th>
                        <td>✅<br>max. 3</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.messenger') }}</th>
                        <td>✅<br>max. 3</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.triggers') }}</th>
                        <td>✅<br>max. 3</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>{{ $t('subscribe.faq.features.features.integrations') }}</th>
                        <td>✅<br>max. 1</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>
                          {{ $t('subscribe.faq.features.features.qp_sync') }}
                          <br>
                          <span class="badge badge-info text-black">OmegaManager required</span>
                          <br>
                          <a href="https://docs.cftools.cloud/en/omegamanager" target="_blank">
                            Details <i class="fal fa-external-link-alt"/>
                          </a>
                        </th>
                        <td>❌</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                      <tr>
                        <th>
                          {{ $t('subscribe.faq.features.features.game_integration') }}
                          <br>
                          <a href="https://docs.cftools.cloud/en/gameintegration" target="_blank">
                            Details, Limits & Availability <i class="fal fa-external-link-alt"/>
                          </a>
                        </th>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>

                    </tbody>
                  </table>
                  <h4 class="text-center">{{ $t('subscribe.faq.features.disclaimer') }}</h4>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>
  </div>
</template>
